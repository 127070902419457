import classNames from 'classnames';
import React from 'react';
import { useTypedSelector } from '../../../../../../../reducers';
import { Device } from '../../../../../../../types/types';
import { getStateEntityByOrgId } from '../../../../../../../utils/conformState';
import { equalityFnc } from '../../../../utils/deviceStateConverter';
import { useDeviceCurrentState } from '../../../../utils/statusConverter';
import { ReservationWithPopover } from '../../CardView/LaunchCard/components/LaunchCardBody/Header/ReservationWithPopover';
import { BatteryColumn } from './components/BatteryColumn';
import { FavoriteColumn } from './components/FavoriteColumn';
import { NameColumn } from './components/NameColumn/NameColumn';
import { StatusColumn } from './components/StatusColumn/StatusColumn';
import './index.scss';

interface Props {
	deviceId: number | string;
	orgId: number | string;
	deviceGroupsNames?: string | null;
	onClickStartSession: (
		device: Device,
		reservationStartTime?: string,
		reservationEndDate?: string,
		isMyReservation?: boolean
	) => void;
	isCalling: boolean;
	publishFavouriteDevice: any;
}

export const LaunchRow = ({
	deviceId,
	orgId,
	deviceGroupsNames,
	onClickStartSession,
	isCalling,
	publishFavouriteDevice,
}: Props) => {
	const device = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'accountState', {
				entityId: deviceId as string,
				orgId: orgId as string,
				propertyOptions: [
					'deviceId',
					'serialNumber',
					'online',
					'status',
					'battery',
					'currentState',
					'name',
					'battery',
					'location',
					'guestReservation',
					'isPermanent',
				],
			}),
		(left, right) => equalityFnc(left, right)
	);

	const accountState = useTypedSelector(state => state.accountState);
	const isFavorite = accountState.user.favouriteDevice?.includes(device.serialNumber) || false;

	const {
		canCall,
		deviceStatus,
		othersNextReservation,
		myNextReservation,
		nextReservation,
	} = useDeviceCurrentState(device, isCalling);
	console.log('device battery', device?.battery);

	return (
		<div className={classNames('rosterListRow oneGoBeListContainer')}>
			<FavoriteColumn
				isFavorite={isFavorite}
				onClick={() => publishFavouriteDevice(device as Device)}
			/>
			<NameColumn
				canCall={canCall}
				onClickStartSession={() =>
					onClickStartSession(device as Device, myNextReservation, othersNextReservation)
				}
				nextReservation={nextReservation}
				deviceStatus={deviceStatus}
				isCalling={isCalling}
				deviceName={device.name}
				isPermanentDevice={device.isPermanent}
			/>
			<StatusColumn
				nextReservation={nextReservation}
				deviceStatus={deviceStatus}
				isCalling={isCalling}
				isPermanentDevice={device.isPermanent}
			/>
			<BatteryColumn
				deviceBattery={
					device?.battery ?? (device?.status ? JSON.parse(device?.status).battery : {})
				}
			/>
			<div className="rosterListRowCell">
				<span className="bodyText">{device.location || 'Location unknown'}</span>
			</div>
			<div className="rosterListRowCell">
				<span className="bodyText">{deviceGroupsNames}</span>
			</div>
			<div
				className="rosterListCellMedium rosterListReservationCell"
				style={{ position: 'relative' }}
			>
				<ReservationWithPopover
					othersNextReservation={othersNextReservation}
					myNextReservation={myNextReservation}
					isPermanentDevice={device.isPermanent}
				/>
			</div>
		</div>
	);
};
