import React, { FC, useEffect, useRef, useState } from 'react';
import {
	IonButton,
	IonIcon,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonGrid,
	IonRow,
} from '@ionic/react';
import {
	checkmarkCircle,
	closeCircle,
	checkmarkCircleOutline,
	reader,
	construct,
	globe,
	trash,
	heartCircleOutline,
	heart,
	fitnessOutline,
	fitness,
	calendarSharp,
} from 'ionicons/icons';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import classes from './RobotEdit.module.css';
import Messages from './RobotEdit.messages';
import RobotForm from '../RobotForm/RobotFormContainer';
import RobotSystem from '../RobotForm/RobotSystem';
import RobotNetwork from '../RobotForm/RobotNetwork';
import RobotHealth from '../RobotForm/RobotHealth';
import { useTypedSelector } from '../../reducers';
import Tooltip from 'react-tooltip-lite';
import { publish } from '../../actions/publish';
import { b64EncodeUnicode } from '../../utils/encoding';
import { useForm } from 'react-hook-form';
import { equalityFnc, getStateEntityByOrgId } from '../../utils/conformState';
import { Device, UVDMongoDevice } from '../../types/types';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { Button, MenuItem } from '@material-ui/core';
import AddGuestReservationModal from '../GuestReservationsModal/AddGuestReservationModal';
import { gql, useMutation, useQuery } from '@apollo/client';
import GuestReservationsGrid from '../GuestReservationsGrid/GuestReservationsGrid';
import DeleteModal from '../DeleteModal/DeleteModal';
const gobeTabs = ['details', 'system', 'network', 'reservations'];
const uvdTabs = ['health', 'details'];

const StyledNewGuestReservationButton = styled(Button)(({ theme }) => ({
	boxShadow: 'none',
	textTransform: 'none',
	color: 'white',
	paddingLeft: '15px',
	paddingRight: '15px',
	backgroundColor: 'var(--ion-color-primary)',
	':hover': {
		backgroundColor: 'var(--ion-color-primary)',
		color: 'white',
		boxShadow: 'none',
	},
}));

const StyledMenuItem = styled(MenuItem)(() => ({
	paddingLeft: 10,
	borderRadius: 5,
}));
interface RobotDetailsProps {
	robotId: string;
	onClose: any;
	device: UVDMongoDevice;
}

const RobotDetails: FC<RobotDetailsProps> = props => {
	const { robotId, onClose, device } = props;
	const [selectedTimeZone, setSelectedTimeZone] = useState('');

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);

	const [saved, setSaved] = useState(false);
	const [editable, setEditable] = useState(false);

	const username = useTypedSelector(state => state.accountState.user.username);
	const orgId = useTypedSelector(state => state.selectedOrganizationState.organization.orgId);
	const orgType = useTypedSelector(state => state.selectedOrganizationState.organization.orgType);
	const encodedUser = b64EncodeUnicode(username);
	const devices = useTypedSelector(state => state.deviceState.devicesByOrganizationId);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	const [selectedSegment, setSelectedSegment] = useState<string>(
		spinoutType === 'uvd' ? uvdTabs[0] : gobeTabs[0]
	);

	const [showAddGuestReservationModal, setShowAddGuestReservationModal] = useState(false);
	const [showDeleteGuestReservationModal, setShowDeleteGuestReservationModal] = useState(false);

	const deleteReservationRef = useRef({});

	const getGuestReservationsQuery = gql`
		query getGuestReservationsQuery($orgId: String!, $serialNumber: String!) {
			getGuestReservations(orgId: $orgId, serialNumber: $serialNumber) {
				orgId
				guest
				guestName
				serialNumber
				startingAt
				createdAt
				host
				hostName
				subject
				endingAt
				createdBy
			}
		}
	`;
	const {
		loading: getGuestReservationsLoading,
		error: getGuestReservationsError,
		data: getGuestReservationsData,
		refetch: refetchGuestReservationsData,
	} = useQuery(getGuestReservationsQuery, {
		variables: { orgId: selectedOrganizationId, serialNumber: device?.deviceId },
		fetchPolicy: 'no-cache',
	});

	const [guestReservations, setGuestReservations] = useState<any>([]);
	useEffect(() => {
		setGuestReservations(getGuestReservationsData?.getGuestReservations || []);
	}, [getGuestReservationsData, getGuestReservationsLoading]);

	const removeGuestReservationMutation = gql`
		mutation removeGuestReservationMutation(
			$orgId: String!
			$serialNumber: String!
			$guest: String!
			$startingAt: DateTime!
		) {
			removeGuestReservation(
				orgId: $orgId
				serialNumber: $serialNumber
				guest: $guest
				startingAt: $startingAt
			) {
				__typename
			}
		}
	`;
	const [removeGuestReservation] = useMutation(removeGuestReservationMutation, {
		onCompleted: data => {
			refetchGuestReservationsData();
		},
	});

	const resendCalendarInvitation = (guestReservation: any) => {
		publish(
			`microservice/${guestReservation?.orgId}/${b64EncodeUnicode(username)}/inviteUser`,
			{
				requestId: 'someId',
				data: {
					firstName: guestReservation?.guestName,
					lastName: '',
					username: guestReservation?.guest,
					orgId: guestReservation?.orgId,
					spinoutType,
					domainUrl: window.location.hostname,
					invitationType: 'calendarInvitation',
					guestReservation: {
						host: guestReservation?.host,
						hostName: guestReservation?.hostName,
						subject: guestReservation?.subject,
						startTime: guestReservation?.startingAt,
						endTime: guestReservation?.endingAt,
					},
				},
			}
		);
	};

	const formik = useFormik({
		initialValues: {
			...device,
		},
		validate: values => {
			let errors: any = {};
			if (!values.name || values.name == '') {
				errors.name = 'Device is required';
			} else {
				if (devices[orgId]) {
					let devicesByOrg = devices[orgId];
					Object.values(devicesByOrg).forEach((element: any) => {
						if (element.name === values.name && element.name !== device.name) {
							errors.name = 'Devices name must be unique';
						}
					});
				}
			}
			return errors;
		},
		onSubmit: (values: any) => {
			onEditRobotSubmit(values);
		},
	});
	const onCancelEdit = () => {
		setEditable(false);
		setSaved(false);
		formik.resetForm();
		formik.setErrors({ errors: {} });
	};

	const onSegmentChange = (value: any) => {
		setSelectedSegment(value);
		setEditable(false);
	};

	const onEditRobot = (data: any) => {
		setEditable(true);
	};

	const onDeleteRobot = () => {
		publish(`microservice/${orgId}/${b64EncodeUnicode(username)}/deleteDevice`, {
			requestId: 'deleteDeviceID',
			data: {
				deviceId: robotId,
			},
		});
		onClose();
	};

	const onEditRobotSubmit = (data: any) => {
		const name = data.name?.trim();
		if (data.name !== name) {
			data.name = name;
			formik.setFieldValue('name', data.name);
		}

		const updateParams: any = {
			name: data.name,
			location: data.location,
			timeZone: selectedTimeZone || data.timeZone,
		};

		if (
			updateParams.name !== device.name ||
			updateParams.location !== device.location ||
			updateParams.timeZone !== device.timeZone
		) {
			publish(`microservice/${selectedOrganizationId}/${encodedUser}/updateDeviceInfo`, {
				requestId: 'updateDeviceInfoId',
				data: {
					device_type: device.deviceType,
					device_id: device.deviceId,
					deviceGroupsIds: Array.isArray(data.deviceGroupsIds)
						? data.deviceGroupsIds
						: [data.deviceGroupsIds],
					name: updateParams.name,
					location: updateParams.location,
					time_zone: updateParams.timeZone,
				},
			});
		}

		setEditable(false);
	};

	return (
		<div>
			<div className={classes.header}>
				<div className={classes.editBtnContainer}>
					<IonButton
						className={
							selectedSegment !== 'details'
								? classes.hidden
								: editable
								? classNames(classes.editBtn, classes.editable)
								: classes.editBtn
						}
						shape="round"
						onClick={onEditRobot}
						disabled={editable}
						data-cy="robot_details_edit_button"
					>
						<IonIcon src="./assets/img/edit.svg" />
					</IonButton>
					{orgType === 'bor' &&
						(!device.deviceGroupsIds || device.deviceGroupsIds.length == 0) && (
							<div className={classes.btnMargin}>
								<Tooltip
									direction="down"
									content={<FormattedMessage {...Messages.deleteTitle} />}
								>
									<IonButton
										className={
											selectedSegment !== 'details'
												? classes.hidden
												: editable
												? classNames(
														classes.editBtn,
														classes.editable,
														classes.trash
												  )
												: classNames(classes.editBtn, classes.trash)
										}
										shape="round"
										onClick={onDeleteRobot}
										disabled={editable}
									>
										<IonIcon icon={trash} />
									</IonButton>
								</Tooltip>
							</div>
						)}
					<div
						className={
							editable
								? classes.formBtns
								: classNames(classes.formBtns, classes.hidden)
						}
					>
						<IonIcon
							slot="end"
							size="large"
							icon={checkmarkCircle}
							onClick={() => formik.handleSubmit()}
							data-cy="robot_details_submit"
						/>
						<IonIcon
							slot="end"
							size="large"
							icon={closeCircle}
							onClick={onCancelEdit}
							data-cy="robot_details_cancel"
						/>
					</div>
					{selectedSegment == 'reservations' && (
						<StyledNewGuestReservationButton
							onClick={() => {
								setShowAddGuestReservationModal(true);
							}}
						>
							New reservation
						</StyledNewGuestReservationButton>
					)}
					{!editable && saved ? (
						<>
							<IonIcon
								slot="end"
								size="small"
								color="primary"
								icon={checkmarkCircleOutline}
							/>
							<IonLabel className={classes.savedLb}>
								<FormattedMessage {...Messages.saved} />
							</IonLabel>
						</>
					) : null}
				</div>
				<IonSegment
					className={classes.tabContainer}
					mode="ios"
					onIonChange={(e: CustomEvent) => onSegmentChange(e.detail.value)}
					value={selectedSegment}
				>
					{spinoutType === 'uvd' ? (
						<>
							<IonSegmentButton
								value="health"
								layout="icon-start"
								className={classes.healthBtn}
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={fitness}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.health} />
								</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton
								value="details"
								layout="icon-start"
								className={classes.detailsBtn}
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={reader}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.robotInfo} />
								</IonLabel>
							</IonSegmentButton>
						</>
					) : (
						<>
							<IonSegmentButton
								value="details"
								layout="icon-start"
								className={classes.detailsBtn}
								data-cy="robot_details_tab"
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={reader}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.details} />
								</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton
								value="system"
								layout="icon-start"
								className={classes.networkBtn}
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={construct}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.system} />
								</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton
								value="network"
								layout="icon-start"
								className={classes.networkBtn}
							>
								<IonIcon className={classes.iconMargin} size="small" icon={globe} />
								<IonLabel>
									<FormattedMessage {...Messages.network} />
								</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton
								value="reservations"
								layout="icon-start"
								className={classes.reservationsBtn}
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={calendarSharp}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.reservations} />
								</IonLabel>
							</IonSegmentButton>
						</>
					)}
				</IonSegment>
			</div>
			<IonGrid className={classes.segmentContent}>
				<IonRow className={selectedSegment !== 'details' ? classes.hidden : ''}>
					<RobotForm
						control={formik}
						device={device}
						deviceId={robotId}
						isEditable={editable}
						selectedTimeZone={setSelectedTimeZone}
					/>
				</IonRow>
				<IonRow className={selectedSegment !== 'system' ? classes.hidden : ''}>
					<RobotSystem deviceId={robotId} isEditable={editable} saved={saved} />
				</IonRow>
				<IonRow className={selectedSegment !== 'network' ? classes.hidden : ''}>
					<RobotNetwork deviceId={robotId} isEditable={editable} saved={saved} />
				</IonRow>
				<IonRow className={selectedSegment !== 'health' ? classes.hidden : ''}>
					<RobotHealth
						deviceId={robotId}
						isEditable={editable}
						saved={saved}
						device={device}
					/>
				</IonRow>
				<IonRow
					className={
						selectedSegment !== 'reservations'
							? classes.hidden
							: classes.reservationContainer
					}
				>
					<GuestReservationsGrid
						data={guestReservations}
						getGuestReservationsLoading={getGuestReservationsLoading}
						selectedDeviceId={device?.deviceId}
						moreContent={(item: any, onClosePopover: any) => {
							return (
								<>
									<StyledMenuItem
										onClick={() => {
											resendCalendarInvitation(item);
											onClosePopover();
										}}
									>
										<FormattedMessage {...Messages.resend} />
									</StyledMenuItem>
									<StyledMenuItem
										onClick={() => {
											deleteReservationRef.current = {
												orgId: item.orgId,
												serialNumber: item.serialNumber,
												guest: item.guest,
												startingAt: item.startingAt,
											};
											setShowDeleteGuestReservationModal(true);
											onClosePopover();
										}}
									>
										<FormattedMessage {...Messages.delete} />
									</StyledMenuItem>
								</>
							);
						}}
					/>
					<AddGuestReservationModal
						isOpen={showAddGuestReservationModal}
						onDismiss={() => setShowAddGuestReservationModal(false)}
						refetchGuestReservationsData={refetchGuestReservationsData}
						selectedDeviceId={device?.deviceId}
					/>
					<DeleteModal
						isOpen={showDeleteGuestReservationModal}
						title={<FormattedMessage {...Messages.deleteTitle} />}
						onConfirm={() => {
							removeGuestReservation({
								variables: { ...deleteReservationRef.current },
							});
							deleteReservationRef.current = {};
							setShowDeleteGuestReservationModal(false);
						}}
						onDismiss={() => {
							deleteReservationRef.current = {};
							setShowDeleteGuestReservationModal(false);
						}}
						itemName="Reservation"
					/>
				</IonRow>
			</IonGrid>
		</div>
	);
};

export default RobotDetails;
