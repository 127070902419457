import React from 'react';
import { IDeviceCurrentState, Reservation } from '../../../../../../utils/statusConverter';
import { DefaultStatusLabel } from './DefaultStatusLabel';
import '../../index.scss';
import { ReservationStatusLabel } from './ReservationStatusLabel';

interface Props {
	nextReservation: Reservation;
	deviceStatus: IDeviceCurrentState;
	isCalling: boolean;
	isPermanentDevice: boolean;
}

export const StatusColumn = ({
	nextReservation,
	deviceStatus,
	isPermanentDevice,
	isCalling,
}: Props) => {
	return (
		<div className="rosterListRowCell">
			{!!nextReservation ? (
				<ReservationStatusLabel
					reservation={nextReservation}
					deviceStatus={deviceStatus}
					isPermanentDevice={isPermanentDevice}
					isCalling={isCalling}
				/>
			) : (
				<DefaultStatusLabel status={deviceStatus} isGuest={false} />
			)}
		</div>
	);
};
