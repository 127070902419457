import React from 'react';
import './ReadyForCallStatusIcon.scss';
import Lottie from 'react-lottie-player';
import preSessionLottieAnimationJSON from './pre-session-animation-row.json';

interface Props {
	isCalling: boolean;
}

export const ReadyForCallStatusIcon = ({ isCalling }: Props) => {
	if (isCalling) {
		return (
			<Lottie
				loop
				animationData={preSessionLottieAnimationJSON}
				play
				speed={1.3}
				className="pre-session-animation"
			/>
		);
	}
	return (
		<div className="listReadyForCallContainer">
			<div className="listPlayContainer">
				<div className="listPlayWrapper">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M11.9686 23.0692C18.0076 23.0692 22.9031 18.1437 22.9031 12.0678C22.9031 5.99189 18.0076 1.06641 11.9686 1.06641C5.92971 1.06641 1.03418 5.99189 1.03418 12.0678C1.03418 18.1437 5.92971 23.0692 11.9686 23.0692Z"
							stroke="#58B255"
							stroke-width="1.5"
						/>
					</svg>
					<div className="listGreenPlayWrapperShadow" />
					<div className="listGreenPlayWrapper">
						<img alt="" src="../../assets/images/green-play.svg" />
					</div>
				</div>
			</div>
		</div>
	);
};
