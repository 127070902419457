import { combineReducers } from 'redux';
import mqttReducers from './mqttReducers';
import mongoDbConfigReducer from './mongoDbConfigReducer';
import accountReducer from './accountReducers';
import accountCreateReducer from './accountCreateReducer';
import accountCreateConfirmedReducer from './accountCreateConfirmedReducer';
import storage from 'redux-persist/lib/storage';
import { SIGN_OUT_USER } from '../actions/types';
import usersReducer from './usersReducer';
import userGroupsReducer from './userGroupsReducer';
import devicesReducer from './devicesReducer';
import deviceGroupsReducer from './deviceGroupsReducer';
import organizationsReducer from './organizationsReducer';
import statusChangeReducer from './statusChangeReducer';
import selectedOrganizationReducer from './selectedOrganizationReducer';
import rolesReducer from './rolesReducer';
import fetchDataReducer from './fetchDataReducer';
import passwordReducer from './passwordReducer';
import menuReducers from './menuReducers';
import hardwareReducer from './hardwareReducer';
import versionReducer from './VersionReducers';
import subAppReducer from '../SubApp/gb_operator_module/src/reducers/index';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

const appReducer = combineReducers({
	mqttState: mqttReducers,
	mongoDbConfigState: mongoDbConfigReducer,
	accountState: accountReducer,
	accountCreateState: accountCreateReducer,
	accountCreateConfirmedReducer: accountCreateConfirmedReducer,
	usersState: usersReducer,
	userGroupsState: userGroupsReducer,
	deviceState: devicesReducer,
	deviceGroupsState: deviceGroupsReducer,
	actionState: statusChangeReducer,
	selectedOrganizationState: selectedOrganizationReducer,
	organizationState: organizationsReducer,
	rolesState: rolesReducer,
	fetchDataState: fetchDataReducer,
	setPasswordState: passwordReducer,
	menuState: menuReducers,
	hardwareState: hardwareReducer,
	versionState: versionReducer,
	goBeState: subAppReducer,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === SIGN_OUT_USER) {
		// for all keys defined in your persistConfig(s)
		storage.removeItem('persist:root');
		localStorage.removeItem('lastVisit');
		localStorage.removeItem('email');
		localStorage.removeItem('psw');
		localStorage.removeItem('organizationID');
		localStorage.removeItem('bookingFleetSharedJwtSecret');
		state = undefined;
	}
	return appReducer(state, action);
};

export type AppRootState = ReturnType<typeof rootReducer>;
/** A typed variant of the useSelector redux hook, that knows the type of this application root state.
 *
 * This is only an effort to prevent repetitively specifying selector types
 */
export const useTypedSelector: TypedUseSelectorHook<AppRootState> = useSelector;

export default rootReducer;
