import { defineMessages } from 'react-intl';

export default defineMessages({
	admin: {
		id: 'admin',
		defaultMessage: 'Admin',
		description: 'Admin user',
	},
	recentActivity: {
		id: 'Users.recentActivity',
		defaultMessage: 'Recent activity',
		description: 'Recent activity',
	},
	serviceInfo: {
		id: 'serviceInfo',
		defaultMessage: 'Service Info',
		description: 'Service Info',
	},
	lastService: {
		id: 'lastService',
		defaultMessage: 'Last service',
	},
	softwareVersion: {
		id: 'softwareVersion',
		defaultMessage: 'Software version',
	},
	servicePackage: {
		id: 'servicePackage',
		defaultMessage: 'Service package',
	},
	serialNumber: {
		id: 'serialNumber',
		defaultMessage: 'Serial number',
	},
	invited: {
		id: 'Users.invited',
		defaultMessage: 'Invited',
		description: 'Invited',
	},
	details: {
		id: 'details',
		defaultMessage: 'Details',
		description: 'Details',
	},
	robotInfo: {
		id: 'robotInfo',
		defaultMessage: 'Robot info',
		description: 'Robot info',
	},
	system: {
		id: 'system',
		defaultMessage: 'System',
		description: 'System',
	},
	network: {
		id: 'network',
		defaultMessage: 'Network',
		description: 'Network',
	},
	reservations: {
		id: 'reservations',
		defaultMessage: 'Reservations',
		description: 'Reservations',
	},
	resend: {
		id: 'resend',
		defaultMessage: 'Resend',
		description: 'Resend',
	},
	delete: {
		id: 'delete',
		defaultMessage: 'Delete',
		description: 'Delete',
	},
	health: {
		id: 'health',
		defaultMessage: 'Health',
		description: 'Health',
	},
	saved: {
		id: 'saved',
		defaultMessage: 'Saved',
		description: 'Saved',
	},
	in: {
		id: 'Users.inGroup',
		defaultMessage: 'In: {group}',
		description: 'Part of user group',
	},
	moreIn: {
		id: 'Users.moreIn',
		defaultMessage: ' and {nr} others',
		description: 'Number of user group',
	},
	and: {
		id: 'and',
		defaultMessage: 'and',
		description: 'and',
	},
	editPicTitle: {
		id: 'editPicTitle',
		defaultMessage: 'Edit {item} Image',
		description: 'Edit image',
	},
	deleteTitle: {
		id: 'deleteTitle',
		defaultMessage: 'Delete Robot',
		description: 'Delete Robot',
	},
});
