import React, { useMemo } from 'react';
import { IoStar, IoStarOutline } from 'react-icons/io5';
import { Device } from '../../../../../../../../../../types/types';
import Battery from '../../../../../../../components/battery';
import { useDeviceCurrentState } from '../../../../../../../utils/statusConverter';
import '../../../index.scss';
import { ReservationWithPopover } from './ReservationWithPopover';

interface Props {
	device: Partial<Device>;
	isFavorite: boolean;
	onFavoriteClick: any;
	isCalling: boolean;
}

export const Header = ({ device, isFavorite, onFavoriteClick, isCalling }: Props) => {
	const { othersNextReservation, myNextReservation } = useDeviceCurrentState(device, isCalling);
	const robotStatus = useMemo(() => {
		const status = {
			battery: {
				level: '0',
				charging: false,
				isCharging: false,
			},
		};
		if (device?.battery && ['level', 'charging'].every(key => key in device?.battery))
			status.battery = device?.battery;
		else {
			const parsedStatus = device.status && JSON.parse(device.status);
			if (parsedStatus) status.battery = parsedStatus.battery;
		}
		return status;
	}, [
		device.battery?.level,
		device.battery?.charging,
		device.battery?.isCharging,
		device.status,
	]);

	return (
		<div className="header">
			<div
				className={isFavorite ? 'iconStarFavorite' : 'iconStar'}
				onClick={() => onFavoriteClick(device as Device)}
			>
				{isFavorite ? (
					<IoStar className="iconStarFavorite" />
				) : (
					<IoStarOutline className="iconStar" />
				)}
			</div>
			<div
				style={{
					position: 'relative',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				<ReservationWithPopover
					isPermanentDevice={device?.isPermanent}
					myNextReservation={myNextReservation}
					othersNextReservation={othersNextReservation}
				/>
				<Battery
					batteryPercent={robotStatus.battery.level}
					online={device.online}
					charging={
						robotStatus.battery && robotStatus.battery.charging
							? robotStatus.battery.charging
							: robotStatus.battery?.isCharging
							? robotStatus.battery.isCharging
							: false
					}
				/>
			</div>
		</div>
	);
};
