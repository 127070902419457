import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { IoTodayOutline } from 'react-icons/io5';
import { Reservation } from '../../../../../../../../utils/statusConverter';
import './index.scss';

interface Props {
	othersNextReservation: Reservation | null | undefined;
	myNextReservation: Reservation | null | undefined;
	isPermanentDevice: boolean | undefined;
}

export const ReservationWithPopover = ({
	othersNextReservation,
	myNextReservation,
	isPermanentDevice,
}: Props) => {
	const [showPopover, setShowPopover] = useState(false);

	const isOthersReservationWithinSevenDays = othersNextReservation
		? moment().isSameOrAfter(
				moment(othersNextReservation?.startDate).subtract(7, 'days'),
				'day'
		  )
		: false;

	const showOthersReservations =
		!!othersNextReservation && isOthersReservationWithinSevenDays && isPermanentDevice;
	const showMyReservations = !!myNextReservation;

	const myNextReservationLabel = useMemo(() => {
		if (!myNextReservation) return null;

		const start = myNextReservation.startDate;
		const end = myNextReservation.endDate;

		switch (myNextReservation.status) {
			case 'beforeReservationDay':
				return {
					label: moment(start).format('D MMMM'),
					date: `${moment(start).format('h:mm')}-${moment(end).format('h:mma')}`,
				};
			case 'reservationDay':
				return {
					label: 'Today',
					date: `${moment(start).format('h:mm')}-${moment(end).format('h:mma')}`,
				};
			case 'reservationTime':
				return {
					label: 'Now',
					date: 'Until ' + moment(end).format('h:mma'),
				};
			case 'inSession':
				return {
					label: 'Now',
					date: 'Until ' + moment(end).format('h:mma'),
				};
			default:
				return { label: '', date: '' };
		}
	}, [myNextReservation]);

	const othersNextReservationLabel = useMemo(() => {
		if (!othersNextReservation) return null;

		const start = othersNextReservation.startDate;
		const end = othersNextReservation.endDate;

		switch (othersNextReservation.status) {
			case 'beforeReservationDay':
				return {
					label: moment(start).format('D MMMM'),
					date: `${moment(start).format('h:mm')}-${moment(end).format('h:mma')}`,
					helperText:
						'Pilots in an ongoing session will be notified 5 minutes before their session ends automatically.',
				};
			case 'reservationDay':
				return {
					label: 'Today',
					date: `${moment(start).format('h:mm')}-${moment(end).format('h:mma')}`,
					helperText:
						'Pilots in an ongoing session will be notified 5 minutes before their session ends automatically.',
				};
			case 'reservationTime':
				return {
					label: 'Now',
					date: 'Until ' + moment(end).format('h:mma'),
					helperText: 'You cannot start a session because the robot is reserved.',
				};
			case 'inSession':
				return {
					label: 'Now',
					date: 'Until ' + moment(end).format('h:mma'),
					helperText: 'You cannot start a session because the robot is reserved.',
				};
			default:
				return { label: '', date: '', helperText: '' };
		}
	}, [othersNextReservation]);

	if (!showMyReservations && !showOthersReservations)
		return <div className="noReservationHeader" />;

	return (
		<div
			className={classNames('reservationOverview')}
			onMouseOver={(e: any) => {
				setShowPopover(true);
			}}
			onMouseOut={(e: any) => {
				setShowPopover(false);
			}}
		>
			<div className="reservedWrapper">
				<IoTodayOutline
					className={classNames('calendarIcon', {
						greenCalendarIcon: !!showMyReservations,
					})}
				/>
			</div>
			<div
				className={classNames('popoverWrapper', {
					popoverHide: !showPopover,
				})}
			>
				{!showMyReservations ? null : (
					<>
						<span className="sectionHeader">My next reservation</span>
						<div className="dateWrapper">
							<span className="bodyM goBePrimaryColor">
								{myNextReservationLabel?.label}
							</span>
							<span className="bodyM goBePrimaryColor">
								{myNextReservationLabel?.date}
							</span>
						</div>
						<p className="caption" style={{ color: '#AAAAAA', marginTop: 6 }}>
							You have exclusive access to the robot during these hours.
						</p>
					</>
				)}
				{!showOthersReservations ? null : (
					<>
						{!showMyReservations ? null : <div className="divider"></div>}
						<span className="sectionHeader">Next reservation for others</span>
						<div className="dateWrapper">
							<span className="bodyM">{othersNextReservationLabel?.label}</span>
							<span className="bodyM">{othersNextReservationLabel?.date}</span>
						</div>
						<p className="caption" style={{ color: '#AAAAAA', marginTop: 6 }}>
							{othersNextReservationLabel?.helperText}
						</p>
					</>
				)}
			</div>
		</div>
	);
};
