import classNames from 'classnames';
import React from 'react';
import { Device } from '../../../../../../../../../../types/types';
import { useDeviceCurrentState } from '../../../../../../../utils/statusConverter';
import '../../../index.scss';
import { DefaultStatus } from './DefaultStatus';
import { ReservationStatus } from './ReservationStatus';

interface Props {
	device: Partial<Device>;
	isCalling: boolean;
	onClickStartSession: (
		device: Device,
		reservationStartTime?: string,
		reservationEndTime?: string,
		isMyReservation?: boolean
	) => void;
}

export const Status = ({ device, isCalling, onClickStartSession }: Props) => {
	const {
		canCall,
		deviceStatus,
		nextReservation,
		myNextReservation,
		othersNextReservation,
	} = useDeviceCurrentState(device, isCalling);

	return (
		<div
			className={classNames('content', {
				pointerCursor: canCall,
			})}
			onClick={() => {
				if (canCall) {
					onClickStartSession(device as Device, myNextReservation, othersNextReservation);
				} else {
					return;
				}
			}}
		>
			<div className="statusContentContainer">
				{!!nextReservation ? (
					<ReservationStatus
						reservation={nextReservation}
						deviceStatus={deviceStatus}
						isPermanentDevice={device.isPermanent}
						isCalling={isCalling}
					/>
				) : (
					<DefaultStatus status={deviceStatus} isCalling={isCalling} />
				)}
			</div>
			<p className="bodyM textAlignCenter location">
				{device?.location ? device.location : 'Location unknown'}
			</p>
		</div>
	);
};
