import {
	UPDATE_MICROPHONE,
	UPDATE_SPEAKER,
	UPDATE_CAMERA,
	UPDATE_MICROPHONE_VOLUME,
	UPDATE_SPEAKERS_VOLUME,
} from '../actions/types';

export type HardwareState = {
	settings: {
		camera: { id: string; name: string } | null;
		microphone: { id: string; name: string } | null;
		speakers: { id: string; name: string } | null;
		microphoneVolume: number;
		speakersVolume: number;
	};
};

const DEFAULT_AUDIO_DEVICE_LEVEL = '40'; // 40 is just an arbitrary value, feel free to change

const initialState: HardwareState = {
	settings: {
		camera: JSON.parse(localStorage.getItem('preferredMediaDevices.camera') ?? 'null'),
		microphone: JSON.parse(localStorage.getItem('preferredMediaDevices.microphone') ?? 'null'),
		speakers: JSON.parse(localStorage.getItem('preferredMediaDevices.speakers') ?? 'null'),
		microphoneVolume: Number.parseFloat(
			localStorage.getItem('preferredMediaDevices.microphoneVolume') ??
				DEFAULT_AUDIO_DEVICE_LEVEL
		),
		speakersVolume: Number.parseFloat(
			localStorage.getItem('preferredMediaDevices.speakersVolume') ??
				DEFAULT_AUDIO_DEVICE_LEVEL
		),
	},
};

export default (state = initialState, action: Record<string, any>) => {
	switch (action.type) {
		case UPDATE_MICROPHONE: {
			const microphone: HardwareState['settings']['microphone'] | null =
				action.payload.microphone;
			return {
				...state,
				settings: {
					...state.settings,
					microphone: microphone ? { ...state.settings.microphone, ...microphone } : null,
				},
			};
		}
		case UPDATE_MICROPHONE_VOLUME: {
			const microphoneVolume: number = action.payload.microphoneVolume;
			return {
				...state,
				settings: {
					...state.settings,
					microphoneVolume,
				},
			};
		}
		case UPDATE_SPEAKER: {
			const speaker: HardwareState['settings']['speakers'] | null = action.payload.speakers;
			return {
				...state,
				settings: {
					...state.settings,
					speakers: speaker ? { ...state.settings.speakers, ...speaker } : null,
				},
			};
		}
		case UPDATE_SPEAKERS_VOLUME: {
			const speakersVolume: number = action.payload.speakersVolume;
			return {
				...state,
				settings: {
					...state.settings,
					speakersVolume,
				},
			};
		}
		case UPDATE_CAMERA: {
			const camera: HardwareState['settings']['camera'] | null = action.payload.camera;
			return {
				...state,
				settings: {
					...state.settings,
					camera: camera ? { ...state.settings.camera, ...camera } : null,
				},
			};
		}
		default:
			return state;
	}
};
